.banner-open {
    width: 96vw;
    max-height: fit-content;
    margin: 2.25rem 0 1rem .2rem;
    border: #dee2e6;
    border-width: 2px;
    border-radius: .2rem;
}

.banner-closed {
    background-color: #fda92c;
    color: #f9f9f9;
    max-width: fit-content;
    margin: 2.25rem 0 1rem .2rem;
    padding: 0;
    border-radius: .2rem;
}

.banner-closed:hover {
    background-color: #5a6268;
}

.banner-top-bar {
    border-bottom-width: 2px;
    border-bottom: #dee2e6;
}

.message-parent {
    overflow: hidden;
    border-style: solid;
    border-color: #6D757D;
    border-width: 1px;
    border-radius: .2rem;
}

.message-child {
    max-height: 18vh;
    overflow-y: auto;
    padding: 6px 8px 0 8px;
    background-color: #dee2e6;
}

.banner-alert-div {
    border-style: solid;
    border-color: #bbbbbb;
    border-width: 1px;
    border-radius: 0.2rem;
    margin-bottom: 0.4rem;
    padding: 8px;
    background-color: #ffffff;
}

.banner-message-title {
    font-weight: bold;
    margin-right: 4px;

}

.banner-alert-link {
    text-decoration: none;
    color: inherit;
    font-weight: normal;
}

.banner-alert-hyperlink {
    text-decoration: none;
    color: #007bff;
    font-weight: normal;
}

.banner-open-toggle-button {
    background-color: #fda92c;
    border-color:  #fda92c;
    border: 0;
    margin-bottom: 0.4rem;
}

.banner-open-toggle-button:hover {
    background-color: #ea991e;
    border-color:   #ea991e;
}

.banner-open-toggle-button:focus {
    background-color: #fda92c;
    border-color:  #fda92c;
    box-shadow: none;
}

.banner-closed-toggle-button {
    background-color: #fda92c;
    border: 0;
}

.banner-closed-toggle-button:hover {
    background-color: #ea991e;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .5s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
