table caption {
    caption-side: top;
}

th label {
    display: block;
}

.overflowModal {
    position: relative;
    display: table;
    overflow-y: auto;
    overflow-x: auto;
    width: auto;
    min-width: 500px;
}

.Toastify__toast-container--top-right {
    width: 500px !important;
}

.rc-tree .rc-tree-treenode {
    padding-bottom: 80px !important;
}