.recommendation-modal {
  max-width: 1200px;
}

.highlight-current {
color: #fda92c;
}

.highlight-recommended {
color: #007bff;
}
.highlight-warning {
border-style: solid;
border-color: #FF0000;
border-width: 3px;
}
.Placeholder {
font-size: 10pt;
color: black;
opacity:  0.75;
padding-left: 5px;
}

.SelectMap {
width: 80%;
}

.marker-cursor {
cursor: pointer;
}

.model-button {
bottom: -5px;
position: absolute;
}

.tooltip [class$="inner"] {
  background-color:  #6c757d;
  color: #fff;
  border: 1px solid  #6c757d;
  border-radius: 0.5rem;
  text-align: left;
  max-width: 300px;
}

.tooltip.show {
  opacity: 1;
} 