body {
    width: 100vw;
    height: 100vh;
    margin: auto;
    padding: 0;
    font-size: 0.8rem;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

.react-bootstrap-table table {
    table-layout: auto;
}

table caption {
    caption-side: top;
}

.overflowModal {
    position: relative;
    display: table;
    overflow-y: auto;
    overflow-x: auto;
    width: auto;
    min-width: 500px;
}

.Toastify__toast-container--top-right {
    width: 500px !important;
}

.rc-tree .rc-tree-treenode {
    padding-bottom: 80px !important;
}

.card {
    background-color: #f9f9f9;
}

.react-bootstrap-table > table > thead > tr > th .filter {
    font-size: 0.8rem;
}

.react-bootstrap-table > table > thead > tr > th .select-filter.placeholder-selected option:not([value='']) {
    font-size: 0.8rem;
}

.form-control {
    font-size: 0.8rem;
}
